// page states for the interface; App.js uses these to determine which
// ui to show
const states = {
    introduction: 0,
    openCoding: 1,
    assistedGrouping: 2,
    verification: 3,
    results: 4,
    ending: 5
}

export default states;